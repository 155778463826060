<template>
  <div class="product__corner_shelf">
    <product-corner-shelf-option
      :key="item.id"
      :disabled="disabled"
      :option="item"
      :is-active="isSelected(item)"
      :is-static="isStatic"
      @click.native="toggle(item)"
    />
    <div v-if="!!hasCurrentOption">
      <label class="product__corner_shelf-label">{{ _translate('Angle inner corner in degrees') }}</label>
      <div
        class="product__corner_shelf__custom-input-wrapper angle-custom-input"
      >
        <input
          :value="angle"
          type="number"
          step="1"
          :min="currentOption.vMinAngle"
          :max="currentOption.vMaxAngle"
          :placeholder="_translate('Degrees')"
          class="product__corner_shelf__custom-input"
          @change="onAngleChange"
        >
        <div class="product__corner_shelf__custom-input-label">
          <div>{{ _translate('Degrees') }}</div>
          <div v-if="description" class="product__corner_shelf-info" @click="onInfoClick" />
        </div>
        <div v-if="angleError" class="product__corner_shelf-error">
          {{ angleError }}
        </div>
      </div>
      <div v-if="!!hasCurrentOption" class="product__corner_shelf-row">
        <div>
          <label class="product__corner_shelf-label">{{ _translate('Left part') }}</label>
          <div
            class="product__corner_shelf__custom-input-wrapper"
          >
            <input
              :value="left"
              type="number"
              step="1"
              :min="1"
              :placeholder="_translate('Left part, cm')"
              class="product__corner_shelf__custom-input"
              @input="onLeftChange"
            >
            <div class="product__corner_shelf__custom-input-label">
              <div>{{ _translate('cm') }}</div>
            </div>
          </div>
        </div>
        <div>
          <label class="product__corner_shelf-label">{{ _translate('Right part') }}</label>
          <div
            class="product__corner_shelf__custom-input-wrapper"
          >
            <input
              :value="right"
              type="number"
              step="1"
              :min="1"
              :placeholder="_translate('Right part, cm')"
              class="product__corner_shelf__custom-input"
              @input="onRightChange"
            >
            <div class="product__corner_shelf__custom-input-label">
              <div>{{ _translate('cm') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!!hasCurrentOption && lengthError" class="product__corner_shelf-error">
      {{ lengthError }}
    </div>
    <div v-if="!!hasCurrentOption && !lengthError" class="product__corner_shelf-help">
      {{ _translate('Enter the length of the left part and the right part will be automatically calculated to add up to the total length selected above.') }}
    </div>

    <div v-if="description" class="show-more__wrapper">
      <div class="product__show-more product__show-more_offset" @click="onInfoClick">
        {{ _translate('More information') }}
      </div>
    </div>
    <popup-component
      v-if="description && isPopupShown"
      :title="descriptionTitle"
      :html="description"
      @close="close"
    />
  </div>
</template>

<script>
import store from '../store'
import ProductCornerShelfOption from './ProductCornerShelfOption.vue'
import PopupComponent from './PopupComponent.vue'

function toNumber (val) {
  return val ? parseInt(val) : -1
}

export default {
  name: 'ProductCornerShelf',
  components: {
    ProductCornerShelfOption,
    PopupComponent
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    disabled: {
      type: Number,
      default: 0
    },
    descriptionTitle: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ...this._getDefaults(),
      angleError: null,
      lengthError: false,
      isPopupShown: false
    }
  },
  computed: {
    hasCurrentOption () {
      return !!(this.currentOption && this.currentOption.id)
    },
    currentOption () {
      return store.state.selectedOptions.v_corner_shelf
    },
    selectedLength () {
      return store.getSelectedLengthVal() || 0
    }
  },
  watch: {
    currentOption (newOption) {
      this.updateInfo(newOption)
    },
    angle (value) {
      if (!this.currentOption || this.disabled) {
        return
      } else if (!value || value < this.currentOption.vMinAngle || value > this.currentOption.vMaxAngle) {
        this.angleError = this._translate('The angle can be minimum %s and maximum %s degrees.', this.currentOption.vMinAngle, this.currentOption.vMaxAngle)
      } else {
        this.angleError = null
      }
      if (!this.angleError && !this.lengthError) {
        this.$emit('changeAdditionInfo', 'v_corner_shelf', {
          angle: Number(value),
          left: Number(this.left),
          right: Number(this.right)
        })
      }
    },
    left (value) {
      this._validateLength('left', value)
    },
    right (value) {
      this._validateLength('right', value)
    },
    angleError (hasError) {
      this.$emit('cornerShelfCorrect', !hasError && !this.lengthError)
    },
    lengthError (hasError) {
      this.$emit('cornerShelfCorrect', !hasError && !this.angleError)
    },
    selectedLength (length) {
      if (this.disabled) {
        this.clearSelection()
        return
      }

      if (!this.visible) {
        return
      }

      length = Number(length || 0)
      const right = length - this.left

      if (right < 0) {
        this.left = String(length)
        this.right = 0
      } else {
        this.right = String(right)
      }
    },
    disabled (value) {
      if (value) {
        this.clearSelection()
      }
    }
  },
  mounted () {
    this.updateInfo(this.currentOption, true)

    if (this.isStatic) {
      this.visible = true
    }

    if (this.disabled) {
      this.clearSelection()
    }
  },
  methods: {
    _translate (key, ...params) {
      let translation = store._translates(key)
      if (params.length) {
        for (const param of params) {
          translation = translation.replace('%s', param)
        }
      }
      return translation
    },
    _getDefaults () {
      return {
        visible: false,
        angle: '',
        left: '',
        right: ''
      }
    },
    toggle (option) {
      if (!this.disabled) {
        this.toggleOption(option)
      }
    },
    toggleOption (option) {
      const value = !this.hasCurrentOption || this.currentOption.id !== option.id
        ? option
        : null

      if (!value && this.isStatic) {
        return
      }

      if (!value) {
        this.visible = false
        this.$emit('cornerShelfCorrect', true)
      } else {
        const values = this._getDefaults()
        this.angle = values.angle
        this.left = values.left
        this.right = values.right
        this.visible = true
      }

      this.$emit('costChange', 'v_corner_shelf', value)
    },
    isSelected (option) {
      return this.hasCurrentOption && this.currentOption.id === option.id
    },
    clearSelection () {
      if (this.hasCurrentOption) {
        this.visible = false
        this.$emit('costChange', 'v_corner_shelf', null)
        this.$emit('cornerShelfCorrect', true)
      }
    },
    onAngleChange (event) {
      this.angle = event.target.value
    },
    onLeftChange (event) {
      this.left = event.target.value
      const other = this.selectedLength - Number(event.target.value)
      if (other >= 0 && Number(event.target.value) >= 0) {
        this.right = other
      }
    },
    onRightChange (event) {
      this.right = event.target.value
      const other = this.selectedLength - Number(event.target.value)
      if (other >= 0 && Number(event.target.value) >= 0) {
        this.left = other
      }
    },
    updateInfo (option, force = false) {
      if (!option) {
        this.$emit('changeAdditionInfo', 'v_corner_shelf', null)
        const defaults = this._getDefaults()
        this.angle = defaults.angle
        this.left = defaults.left
        this.right = defaults.right
        return
      }
      const infoVal = store.state.additionInfo.v_corner_shelf
      let angle, left, right

      if (infoVal) {
        angle = toNumber(infoVal.angle)
        left = toNumber(infoVal.left)
        right = toNumber(infoVal.right)

        if (angle < option.vMinAngle || angle > option.vMaxAngle) {
          angle = option.vDefaultAngle
        }

        if (left < 1 || left > this.selectedLength) {
          left = Math.round(this.selectedLength / 2)
        }

        if (right < 1) {
          right = this.selectedLength - left
        }
      } else {
        angle = option.vDefaultAngle
        left = Math.round(this.selectedLength / 2)
        right = this.selectedLength - left
      }

      if (
        !infoVal ||
          angle !== infoVal.angle ||
          left !== infoVal.left ||
          right !== infoVal.right ||
          force
      ) {
        this.angle = String(angle)
        this.left = String(left)
        this.right = String(right)
        this.$emit('changeAdditionInfo', 'v_corner_shelf', { angle, left, right })
      }
    },
    _validateLength (param, value) {
      if (!this.visible) {
        return
      }

      const otherValue = param === 'left' ? this.right : this.left

      if (!this.currentOption || this.disabled) {
        this.lengthError = null
      } else if (!value || value < 1 || Number(value) === this.selectedLength) {
        this.lengthError = this._translate('Enter correct values')
      } else if (Number(value) + Number(otherValue) !== this.selectedLength) {
        this.lengthError = this._translate('Sum of left and right parts must be equal the shelf length')
      } else {
        this.lengthError = null
      }
      if (!this.angleError && !this.lengthError) {
        const data = {
          angle: this.angle
        }

        if (param === 'left') {
          data.left = Number(value)
          data.right = Number(otherValue)
        } else {
          data.left = Number(otherValue)
          data.right = Number(value)
        }

        this.$emit('changeAdditionInfo', 'v_corner_shelf', data)
      }
    },
    onInfoClick () {
      if (this.disabled) {
        return
      }
      this.isPopupShown = true
    },
    close () {
      this.isPopupShown = false
    }
  }
}
</script>

<style scoped lang="scss">
.product__corner_shelf {
  &-row {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: .75rem;
  }

  &-label {
    font-family: Ladislav, serif;
    display: block;
    margin-bottom: .5rem;
    font-size: 1rem;
  }

  &-error {
    color: #ff5858;
    margin-top: .25rem;
  }

  &__custom-input {
    width: 100%;
    padding: 12px 3rem 12px 16px;
    border-radius: 2px;
    border: solid 2px #333;
    cursor: pointer;
    position: relative;
    display: block;
    color: #fff;
    outline: none;
    font-family: "FuturaPT", sans-serif;
    line-height: 1.5;
    -moz-appearance: textfield;

    .angle-custom-input & {
      padding-right: 6.5rem;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:hover, &:focus {
      border-color: #616161;
    }
    &-wrapper {
      position: relative;
    }
    &-label {
      position: absolute;
      line-height: 24px;
      right: 16px;
      top: 14px;
      user-select: none;
      display: flex;
      align-items: center;
    }
  }

  &-info {
    margin-left: 10px;
    opacity: 0.5;
    background-image: url("../../assets/info.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    transition: 0.3s;

    :not(.product__corner_shelf_option_disabled) > & {
      cursor: pointer;
    }

    :not(.product__corner_shelf_option_disabled) > &:hover {
      opacity: 1;
    }
  }
}
.product__show-more {
  margin-top: 1rem;
}
.product__show-more:not(:hover) {
  color: #b2b2b2 !important;
}
</style>
